/**
 *  Helper functions
 */

function setAttributes(data) {
    for (let i = 0; i < data.attributes.length; i += 1) {
        const atr = data.attributes[i];
        const item = $(`#attribute-wrapper-form .attribute-section[data-section=${i}]`);

        // Set attributes
        item.find(".attribute-select option")
            .filter(function () {
                try {
                    const dict = JSON.parse($(this).val());
                    return dict.nl === atr.key_nl && dict.en === atr.key_en;
                } catch (e) {
                    return false;
                }
            })
            .prop("selected", true);
        item.find(".attribute-select option").trigger("change");

        // Set attribute values
        item.find(".attribute-option option")
            .filter(function () {
                try {
                    const dict = JSON.parse($(this).val());
                    return dict.nl === atr.value_nl && dict.en === atr.value_en;
                } catch (e) {
                    return false;
                }
            })
            .prop("selected", true);
        item.find(".attribute-option option").trigger("change");
    }
}
window.setAttributes = setAttributes;

/**
 *  Whenever a new option is selected, hide all options for the attribute and show the correct one
 *  Also deselect any values that where selected
 *  Add a new field
 *  The form boolean says if it is the form of the modal
 */
function attributeSetOption(select) {
    const sectionId = parseInt($(select).parent().parent().attr("data-section"));
    const selectedOption = select.options[select.selectedIndex];

    $(`.attribute-section[data-section=${sectionId}] .attribute-option`).hide();
    $(
        `.attribute-section[data-section=${sectionId}] .attribute-option[data-id=${selectedOption.dataset.option_id}]`
    ).show();
    // $(`${wrapperId} .attribute-option-${optionIndex} option`).removeAttr("selected");
    // $(`${wrapperId} .attribute-option-${optionIndex} #attribute-select-value`).val(trans.forms["select-attr-val"]);
    // $(`${wrapperId} .attribute-section-${optionIndex} #attribute-value-${selectedOption.dataset.option_id}`).show();

    const lastSection = parseInt($(`.attribute-section`).last().attr("data-section"));
    // alert(lastSection)

    if (lastSection === sectionId) {
        const copy = $(`.attribute-section[data-section=0]`).clone();
        copy.attr("data-section", sectionId + 1);
        copy.children(`.attribute-option`).hide();
        $(`.attribute-section[data-section=0]`).parent().append(copy);
    }
}
window.attributeSetOption = attributeSetOption;
